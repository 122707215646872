import React, { useState } from 'react';
import MediumModal from './MediumModal';

const MediumCard = ({ article }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1 w-full">
                {article.thumbnail && (
                    <div className="relative h-48 overflow-hidden">
                        <img
                            src={article.thumbnail}
                            alt={article.title}
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                    </div>
                )}

                <div className="p-6">
                    <p className="text-sm text-gray-500 mb-2">
                        {new Date(article.pubDate).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </p>

                    <h3 className="text-xl font-semibold mb-3 line-clamp-2 min-h-[3.5rem]">
                        {article.title}
                    </h3>

                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="inline-flex items-center justify-center px-4 py-2 bg-black text-white rounded-lg hover:bg-yellow hover:text-black transition-colors duration-200"
                    >
                        Read Article
                        <svg
                            className="w-4 h-4 ml-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <MediumModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                article={article}
            />
        </>
    );
};

export default MediumCard;