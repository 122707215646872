import React from 'react'
import MediumArticles from '../Components/BlogPostPageComponent/MediumArticles'

const BlogsPage = () => {
    return (
        <>
            <h1 className='text-center text-5xl p-5 font-gotham-black'>Blogs</h1>
            <MediumArticles />
        </>
    )
}

export default BlogsPage