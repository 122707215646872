import React, { useEffect, useRef } from 'react';

const MediumModal = ({ isOpen, onClose, article }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            // Prevent scrolling of the body when modal is open
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            // Restore scrolling when modal is closed
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div
                ref={modalRef}
                className="bg-white rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto relative animate-modal-enter"
            >
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>

                {/* Content */}
                <div className="p-6">
                    {article.thumbnail && (
                        <img
                            src={article.thumbnail}
                            alt={article.title}
                            className="w-full h-64 object-cover rounded-lg mb-6"
                        />
                    )}

                    <h2 className="text-2xl font-bold mb-4">{article.title}</h2>

                    <p className="text-sm text-gray-500 mb-4">
                        Published on {new Date(article.pubDate).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </p>

                    <div
                        className="prose max-w-none"
                        dangerouslySetInnerHTML={{ __html: article.description }}
                    />

                    <div className="mt-6 flex justify-end">
                        <a
                            href={article.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center justify-center px-6 py-3 bg-black text-white rounded-lg hover:bg-yellow hover:text-black transition-colors duration-200"
                        >
                            Read Full Article on Medium
                            <svg
                                className="w-4 h-4 ml-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediumModal;